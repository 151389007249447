<template>
  <v-container fluid row justify-end>
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
      v-show="x.loading"
    ></v-progress-linear>
    <v-card color="white">
      <v-card-title primary-title class="bg-clr" v-permissions="'create-user'">
        <v-btn fab flat icon class="nospace" @click.native="close">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <span class="headline">&nbsp;New User</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" onsubmit="return false;">
          <v-container grid-list-md>
            <div class="body-2 red--text">* indicated fields are mandatory</div>
            <v-layout wrap>
              <v-flex md5>
                <v-flex xs12 class="pr-4" sm6 md12>
                  <v-text-field
                    label="Name of User *"
                    v-model="name"
                    required
                    single-line
                    :rules="[rules.noWhiteSpace, rules.required]"
                  />
                </v-flex>
                <v-flex xs12 class="pr-4" sm6 md12 v-if="this.arabicField">
                  <v-text-field
                    label="Name of User in arabic *"
                    v-model="nameArabic"
                    required
                    single-line
                    :rules="[
                      rules.noWhiteSpace,
                      rules.required,
                      rules.arabicLanguage,
                    ]"
                  />
                </v-flex>
                <v-flex xs12 class="pr-4" sm6 md12>
                  <v-autocomplete
                    v-model="countryCode"
                    id="countryCode"
                    item-text="dialing_code"
                    item-value="dialing_code"
                    label="Country Code *"
                    :items="countries"
                    persistent-hint
                    return-object
                    single-line
                  ></v-autocomplete>
                  <v-flex>
                    <v-autocomplete
                      v-model="countrySortName"
                      label="Working Country *"
                      :items="multipleCountry"
                      item-text="value"
                      item-value="value"
                      multiple
                      :rules="[rules.requiredLocation]"
                      single-line
                    />
                  </v-flex>
                  <v-autocomplete
                    v-model="country"
                    id="country"
                    item-text="country_name"
                    item-value="country_name"
                    label="Select Country *"
                    :items="countries"
                    persistent-hint
                    return-object
                    :rules="[rules.required]"
                    single-line
                  ></v-autocomplete>

                  <v-select
                    v-model="selectedUserType"
                    :items="userTypeItems"
                    item-text="userTypeTitle"
                    item-value="_id"
                    label="Select User Type *"
                    persistent-hint
                    return-object
                    :rules="[rules.required]"
                    single-line
                  ></v-select>
                </v-flex>
              </v-flex>
              <v-flex md5>
                <v-flex xs12 class="pr-4" sm6 md12>
                  <v-text-field
                    label="Email Id *"
                    :rules="[rules.emailRules, rules.required]"
                    v-model="email"
                    single-line
                    required
                  />
                </v-flex>
                <v-flex xs12 class="pr-4" sm6 md12>
                  <v-text-field
                    label="Phone No *"
                    v-model="phoneNo"
                    single-line
                    :rules="[rules.phoneNumber, rules.required]"
                  />
                </v-flex>

                <v-select
                  v-model="language"
                  :items="languageArray"
                  item-text="lang"
                  item-value="_id"
                  label="Select Language *"
                  persistent-hint
                  return-object
                  :rules="[rules.required]"
                  single-line
                ></v-select>
                <v-select
                  v-model="roleType"
                  :items="roleTypes"
                  item-text="name"
                  item-value="value"
                  label="Select Designation *"
                  persistent-hint
                  :rules="[rules.required]"
                  return-object
                  single-line
                ></v-select>
                <v-select
                  v-if="showroles"
                  v-model="selectedRole"
                  :items="roles"
                  item-text="roleTitle"
                  item-value="_id"
                  label="Select Role"
                  persistent-hint
                  :rules="[rules.required]"
                  return-object
                  single-line
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>

        <div
          class="my-3"
          v-if="haserror"
          style="width: 50%; margin: auto; text-align: center"
        >
          <v-alert v-model="haserror" class="py-2" outline color="red">{{
            x.error
          }}</v-alert>
        </div>
      </v-card-text>
      <v-divider class="my-3" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          size="large"
          color="grey darken-3"
          flat
          style="color: white"
          @click.native="close"
          >Close</v-btn
        >
        <v-btn
          size="large"
          color="orange darken-1"
          @click.native="register"
          style="color: white"
          :loading="x.loading"
          >Register User</v-btn
        >
        <v-spacer />
      </v-card-actions>
      <v-divider class="my-3" />
    </v-card>
    <success-dialog
      content="User registration successful"
      :show="x.registerSuccess"
      :onclose="closeRegistration"
    />
  </v-container>
</template>

<script>
import SuccessDialog from "@/components/Common/SuccessDialog";
import moment from "moment";
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { user, getAllRoles, getLanguage } from "../../constants/api-urls";
export default {
  components: {
    SuccessDialog,
    ErrorBox,
  },
  mounted() {
    this.getLanguage();
    this.getAllUserType();
  },
  updated() {
    document.getElementById("country").setAttribute("autocomplete", "none");
    document.getElementById("countryCode").setAttribute("autocomplete", "none");
  },
  data() {
    return {
      valid: true,
      lazy: false,
      countryCurrency: [],
      countrySortName: [],
      multipleCountry: [
        { value: "KSA", currency: "SAR" },
        { value: "UAE", currency: "AED" },
        { value: "PAK", currency: "PKR" },
        { value: "UAE LA", currency: "AED" },
        { value: "OMAN", currency: "OMR" },
      ],
      languageArray: [],
      countries: [
        {
          country_code: "AF",
          country_name: "Afghanistan",
          dialing_code: "+93",
          index: 0,
        },
        {
          country_code: "AL",
          country_name: "Albania",
          dialing_code: "+355",
          index: 1,
        },
        {
          country_code: "DZ",
          country_name: "Algeria",
          dialing_code: "+213",
          index: 2,
        },
        {
          country_code: "AS",
          country_name: "American Samoa",
          dialing_code: "+1",
          index: 3,
        },
        {
          country_code: "AD",
          country_name: "Andorra",
          dialing_code: "+376",
          index: 4,
        },
        {
          country_code: "AO",
          country_name: "Angola",
          dialing_code: "+244",
          index: 5,
        },
        {
          country_code: "AI",
          country_name: "Anguilla",
          dialing_code: "+1",
          index: 6,
        },
        {
          country_code: "AG",
          country_name: "Antigua",
          dialing_code: "+1",
          index: 7,
        },
        {
          country_code: "AR",
          country_name: "Argentina",
          dialing_code: "+54",
          index: 8,
        },
        {
          country_code: "AM",
          country_name: "Armenia",
          dialing_code: "+374",
          index: 9,
        },
        {
          country_code: "AW",
          country_name: "Aruba",
          dialing_code: "+297",
          index: 10,
        },
        {
          country_code: "AU",
          country_name: "Australia",
          dialing_code: "+61",
          index: 11,
        },
        {
          country_code: "AI",
          country_name: "Austria",
          dialing_code: "+43",
          index: 12,
        },
        {
          country_code: "AZ",
          country_name: "Azerbaijan",
          dialing_code: "+994",
          index: 13,
        },
        {
          country_code: "BH",
          country_name: "Bahrain",
          dialing_code: "+973",
          index: 14,
        },
        {
          country_code: "BD",
          country_name: "Bangladesh",
          dialing_code: "+880",
          index: 15,
        },
        {
          country_code: "BB",
          country_name: "Barbados",
          dialing_code: "+1",
          index: 16,
        },
        {
          country_code: "BY",
          country_name: "Belarus",
          dialing_code: "+375",
          index: 17,
        },
        {
          country_code: "BE",
          country_name: "Belgium",
          dialing_code: "+32",
          index: 18,
        },
        {
          country_code: "BZ",
          country_name: "Belize",
          dialing_code: "+501",
          index: 19,
        },
        {
          country_code: "BJ",
          country_name: "Benin",
          dialing_code: "+229",
          index: 20,
        },
        {
          country_code: "BM",
          country_name: "Bermuda",
          dialing_code: "+1",
          index: 21,
        },
        {
          country_code: "BT",
          country_name: "Bhutan",
          dialing_code: "+975",
          index: 22,
        },
        {
          country_code: "BO",
          country_name: "Bolivia",
          dialing_code: "+591",
          index: 23,
        },
        {
          country_code: "BA",
          country_name: "Bosnia and Herzegovina",
          dialing_code: "+387",
          index: 24,
        },
        {
          country_code: "BW",
          country_name: "Botswana",
          dialing_code: "+267",
          index: 25,
        },
        {
          country_code: "BR",
          country_name: "Brazil",
          dialing_code: "+55",
          index: 26,
        },
        {
          country_code: "IO",
          country_name: "British Indian Ocean Territory",
          dialing_code: "+246",
          index: 27,
        },
        {
          country_code: "VG",
          country_name: "British Virgin Islands",
          dialing_code: "+1",
          index: 28,
        },
        {
          country_code: "BN",
          country_name: "Brunei",
          dialing_code: "+673",
          index: 29,
        },
        {
          country_code: "BG",
          country_name: "Bulgaria",
          dialing_code: "+359",
          index: 30,
        },
        {
          country_code: "BF",
          country_name: "Burkina Faso",
          dialing_code: "+226",
          index: 31,
        },
        {
          country_code: "MM",
          country_name: "Burma Myanmar",
          dialing_code: "+95",
          index: 32,
        },
        {
          country_code: "BI",
          country_name: "Burundi",
          dialing_code: "+257",
          index: 33,
        },
        {
          country_code: "KH",
          country_name: "Cambodia",
          dialing_code: "+855",
          index: 34,
        },
        {
          country_code: "CM",
          country_name: "Cameroon",
          dialing_code: "+237",
          index: 35,
        },
        {
          country_code: "CA",
          country_name: "Canada",
          dialing_code: "+1",
          index: 36,
        },
        {
          country_code: "CV",
          country_name: "Cape Verde",
          dialing_code: "+238",
          index: 37,
        },
        {
          country_code: "KY",
          country_name: "Cayman Islands",
          dialing_code: "+1",
          index: 38,
        },
        {
          country_code: "CF",
          country_name: "Central African Republic",
          dialing_code: "+236",
          index: 39,
        },
        {
          country_code: "ID",
          country_name: "Chad",
          dialing_code: "+235",
          index: 40,
        },
        {
          country_code: "CL",
          country_name: "Chile",
          dialing_code: "+56",
          index: 41,
        },
        {
          country_code: "CN",
          country_name: "China",
          dialing_code: "+86",
          index: 42,
        },
        {
          country_code: "CO",
          country_name: "Colombia",
          dialing_code: "+57",
          index: 43,
        },
        {
          country_code: "KM",
          country_name: "Comoros",
          dialing_code: "+269",
          index: 44,
        },
        {
          country_code: "CK",
          country_name: "Cook Islands",
          dialing_code: "+682",
          index: 45,
        },
        {
          country_code: "CR",
          country_name: "Costa Rica",
          dialing_code: "+506",
          index: 46,
        },
        {
          country_code: "CI",
          country_name: "Cote d'Ivoire",
          dialing_code: "+225",
          index: 47,
        },
        {
          country_code: "HR",
          country_name: "Croatia",
          dialing_code: "+385",
          index: 48,
        },
        {
          country_code: "CU",
          country_name: "Cuba",
          dialing_code: "+53",
          index: 49,
        },
        {
          country_code: "CY",
          country_name: "Cyprus",
          dialing_code: "+357",
          index: 50,
        },
        {
          country_code: "CZ",
          country_name: "Czech Republic",
          dialing_code: "+420",
          index: 51,
        },
        {
          country_code: "CD",
          country_name: "Democratic Republic of Congo",
          dialing_code: "+243",
          index: 52,
        },
        {
          country_code: "DK",
          country_name: "Denmark",
          dialing_code: "+45",
          index: 53,
        },
        {
          country_code: "DJ",
          country_name: "Djibouti",
          dialing_code: "+253",
          index: 54,
        },
        {
          country_code: "DM",
          country_name: "Dominica",
          dialing_code: "+1",
          index: 55,
        },
        {
          country_code: "DO",
          country_name: "Dominican Republic",
          dialing_code: "+1",
          index: 56,
        },
        {
          country_code: "EC",
          country_name: "Ecuador",
          dialing_code: "+593",
          index: 57,
        },
        {
          country_code: "EG",
          country_name: "Egypt",
          dialing_code: "+20",
          index: 58,
        },
        {
          country_code: "SV",
          country_name: "El Salvador",
          dialing_code: "+503",
          index: 59,
        },
        {
          country_code: "GQ",
          country_name: "Equatorial Guinea",
          dialing_code: "+240",
          index: 60,
        },
        {
          country_code: "ER",
          country_name: "Eritrea",
          dialing_code: "+291",
          index: 61,
        },
        {
          country_code: "EE",
          country_name: "Estonia",
          dialing_code: "+372",
          index: 62,
        },
        {
          country_code: "ET",
          country_name: "Ethiopia",
          dialing_code: "+251",
          index: 63,
        },
        {
          country_code: "FK",
          country_name: "Falkland Islands",
          dialing_code: "+500",
          index: 64,
        },
        {
          country_code: "FO",
          country_name: "Faroe Islands",
          dialing_code: "+298",
          index: 65,
        },
        {
          country_code: "FM",
          country_name: "Federated States of Micronesia",
          dialing_code: "+691",
          index: 66,
        },
        {
          country_code: "FJ",
          country_name: "Fiji",
          dialing_code: "+679",
          index: 67,
        },
        {
          country_code: "FI",
          country_name: "Finland",
          dialing_code: "+358",
          index: 68,
        },
        {
          country_code: "FR",
          country_name: "France",
          dialing_code: "+33",
          index: 69,
        },
        {
          country_code: "GF",
          country_name: "French Guiana",
          dialing_code: "+594",
          index: 70,
        },
        {
          country_code: "PF",
          country_name: "French Polynesia",
          dialing_code: "+689",
          index: 71,
        },
        {
          country_code: "GA",
          country_name: "Gabon",
          dialing_code: "+241",
          index: 72,
        },
        {
          country_code: "GE",
          country_name: "Georgia",
          dialing_code: "+995",
          index: 73,
        },
        {
          country_code: "DE",
          country_name: "Germany",
          dialing_code: "+49",
          index: 74,
        },
        {
          country_code: "GH",
          country_name: "Ghana",
          dialing_code: "+233",
          index: 75,
        },
        {
          country_code: "GI",
          country_name: "Gibraltar",
          dialing_code: "+350",
          index: 76,
        },
        {
          country_code: "GR",
          country_name: "Greece",
          dialing_code: "+30",
          index: 77,
        },
        {
          country_code: "GL",
          country_name: "Greenland",
          dialing_code: "+299",
          index: 78,
        },
        {
          country_code: "GD",
          country_name: "Grenada",
          dialing_code: "+1",
          index: 79,
        },
        {
          country_code: "GP",
          country_name: "Guadeloupe",
          dialing_code: "+590",
          index: 80,
        },
        {
          country_code: "GU",
          country_name: "Guam",
          dialing_code: "+1",
          index: 81,
        },
        {
          country_code: "GT",
          country_name: "Guatemala",
          dialing_code: "+502",
          index: 82,
        },
        {
          country_code: "GN",
          country_name: "Guinea",
          dialing_code: "+224",
          index: 83,
        },
        {
          country_code: "GW",
          country_name: "Guinea-Bissau",
          dialing_code: "+245",
          index: 84,
        },
        {
          country_code: "GY",
          country_name: "Guyana",
          dialing_code: "+592",
          index: 85,
        },
        {
          country_code: "HT",
          country_name: "Haiti",
          dialing_code: "+509",
          index: 86,
        },
        {
          country_code: "HN",
          country_name: "Honduras",
          dialing_code: "+504",
          index: 87,
        },
        {
          country_code: "HK",
          country_name: "Hong Kong",
          dialing_code: "+852",
          index: 88,
        },
        {
          country_code: "HU",
          country_name: "Hungary",
          dialing_code: "+36",
          index: 89,
        },
        {
          country_code: "IS",
          country_name: "Iceland",
          dialing_code: "+354",
          index: 90,
        },
        {
          country_code: "IN",
          country_name: "India",
          dialing_code: "+91",
          index: 91,
        },
        {
          country_code: "ID",
          country_name: "Indonesia",
          dialing_code: "+62",
          index: 92,
        },
        {
          country_code: "IR",
          country_name: "Iran",
          dialing_code: "+98",
          index: 93,
        },
        {
          country_code: "IQ",
          country_name: "Iraq",
          dialing_code: "+964",
          index: 94,
        },
        {
          country_code: "IE",
          country_name: "Ireland",
          dialing_code: "+353",
          index: 95,
        },
        {
          country_code: "IL",
          country_name: "Israel",
          dialing_code: "+972",
          index: 96,
        },
        {
          country_code: "IT",
          country_name: "Italy",
          dialing_code: "+39",
          index: 97,
        },
        {
          country_code: "JM",
          country_name: "Jamaica",
          dialing_code: "+1",
          index: 98,
        },
        {
          country_code: "JP",
          country_name: "Japan",
          dialing_code: "+81",
          index: 99,
        },
        {
          country_code: "JO",
          country_name: "Jordan",
          dialing_code: "+962",
          index: 100,
        },
        {
          country_code: "KZ",
          country_name: "Kazakhstan",
          dialing_code: "+7",
          index: 101,
        },
        {
          country_code: "KE",
          country_name: "Kenya",
          dialing_code: "+254",
          index: 102,
        },
        {
          country_code: "KI",
          country_name: "Kiribati",
          dialing_code: "+686",
          index: 103,
        },
        {
          country_code: "XK",
          country_name: "Kosovo",
          dialing_code: "+381",
          index: 104,
        },
        {
          country_code: "KW",
          country_name: "Kuwait",
          dialing_code: "+965",
          index: 105,
        },
        {
          country_code: "KG",
          country_name: "Kyrgyzstan",
          dialing_code: "+996",
          index: 106,
        },
        {
          country_code: "LA",
          country_name: "Laos",
          dialing_code: "+856",
          index: 107,
        },
        {
          country_code: "LV",
          country_name: "Latvia",
          dialing_code: "+371",
          index: 108,
        },
        {
          country_code: "LB",
          country_name: "Lebanon",
          dialing_code: "+961",
          index: 109,
        },
        {
          country_code: "LS",
          country_name: "Lesotho",
          dialing_code: "+266",
          index: 110,
        },
        {
          country_code: "LR",
          country_name: "Liberia",
          dialing_code: "+231",
          index: 111,
        },
        {
          country_code: "LY",
          country_name: "Libya",
          dialing_code: "+218",
          index: 112,
        },
        {
          country_code: "LI",
          country_name: "Liechtenstein",
          dialing_code: "+423",
          index: 113,
        },
        {
          country_code: "LT",
          country_name: "Lithuania",
          dialing_code: "+370",
          index: 114,
        },
        {
          country_code: "LU",
          country_name: "Luxembourg",
          dialing_code: "+352",
          index: 115,
        },
        {
          country_code: "MO",
          country_name: "Macau",
          dialing_code: "+853",
          index: 116,
        },
        {
          country_code: "MK",
          country_name: "Macedonia",
          dialing_code: "+389",
          index: 117,
        },
        {
          country_code: "MG",
          country_name: "Madagascar",
          dialing_code: "+261",
          index: 118,
        },
        {
          country_code: "MW",
          country_name: "Malawi",
          dialing_code: "+265",
          index: 119,
        },
        {
          country_code: "MY",
          country_name: "Malaysia",
          dialing_code: "+60",
          index: 120,
        },
        {
          country_code: "MV",
          country_name: "Maldives",
          dialing_code: "+960",
          index: 121,
        },
        {
          country_code: "ML",
          country_name: "Mali",
          dialing_code: "+223",
          index: 122,
        },
        {
          country_code: "MT",
          country_name: "Malta",
          dialing_code: "+356",
          index: 123,
        },
        {
          country_code: "MH",
          country_name: "Marshall Islands",
          dialing_code: "+692",
          index: 124,
        },
        {
          country_code: "MQ",
          country_name: "Martinique",
          dialing_code: "+596",
          index: 125,
        },
        {
          country_code: "MR",
          country_name: "Mauritania",
          dialing_code: "+222",
          index: 126,
        },
        {
          country_code: "MU",
          country_name: "Mauritius",
          dialing_code: "+230",
          index: 127,
        },
        {
          country_code: "YT",
          country_name: "Mayotte",
          dialing_code: "+262",
          index: 128,
        },
        {
          country_code: "MX",
          country_name: "Mexico",
          dialing_code: "+52",
          index: 129,
        },
        {
          country_code: "MD",
          country_name: "Moldova",
          dialing_code: "+373",
          index: 130,
        },
        {
          country_code: "MC",
          country_name: "Monaco",
          dialing_code: "+377",
          index: 131,
        },
        {
          country_code: "MN",
          country_name: "Mongolia",
          dialing_code: "+976",
          index: 132,
        },
        {
          country_code: "ME",
          country_name: "Montenegro",
          dialing_code: "+382",
          index: 133,
        },
        {
          country_code: "MS",
          country_name: "Montserrat",
          dialing_code: "+1",
          index: 134,
        },
        {
          country_code: "MA",
          country_name: "Morocco",
          dialing_code: "+212",
          index: 135,
        },
        {
          country_code: "MZ",
          country_name: "Mozambique",
          dialing_code: "+258",
          index: 136,
        },
        {
          country_code: "NA",
          country_name: "Namibia",
          dialing_code: "+264",
          index: 137,
        },
        {
          country_code: "NR",
          country_name: "Nauru",
          dialing_code: "+674",
          index: 138,
        },
        {
          country_code: "NP",
          country_name: "Nepal",
          dialing_code: "+977",
          index: 139,
        },
        {
          country_code: "NL",
          country_name: "Netherlands",
          dialing_code: "+31",
          index: 140,
        },
        {
          country_code: "AN",
          country_name: "Netherlands Antilles",
          dialing_code: "+599",
          index: 141,
        },
        {
          country_code: "NC",
          country_name: "New Caledonia",
          dialing_code: "+687",
          index: 142,
        },
        {
          country_code: "NZ",
          country_name: "New Zealand",
          dialing_code: "+64",
          index: 143,
        },
        {
          country_code: "NI",
          country_name: "Nicaragua",
          dialing_code: "+505",
          index: 144,
        },
        {
          country_code: "NE",
          country_name: "Niger",
          dialing_code: "+227",
          index: 145,
        },
        {
          country_code: "NG",
          country_name: "Nigeria",
          dialing_code: "+234",
          index: 146,
        },
        {
          country_code: "NU",
          country_name: "Niue",
          dialing_code: "+683",
          index: 147,
        },
        {
          country_code: "NF",
          country_name: "Norfolk Island",
          dialing_code: "+672",
          index: 148,
        },
        {
          country_code: "KP",
          country_name: "North Korea",
          dialing_code: "+850",
          index: 149,
        },
        {
          country_code: "MP",
          country_name: "Northern Mariana Islands",
          dialing_code: "+1",
          index: 150,
        },
        {
          country_code: "NO",
          country_name: "Norway",
          dialing_code: "+47",
          index: 151,
        },
        {
          country_code: "OM",
          country_name: "Oman",
          dialing_code: "+968",
          index: 152,
        },
        {
          country_code: "PK",
          country_name: "Pakistan",
          dialing_code: "+92",
          index: 153,
        },
        {
          country_code: "PW",
          country_name: "Palau",
          dialing_code: "+680",
          index: 154,
        },
        {
          country_code: "PS",
          country_name: "Palestine",
          dialing_code: "+970",
          index: 155,
        },
        {
          country_code: "PA",
          country_name: "Panama",
          dialing_code: "+507",
          index: 156,
        },
        {
          country_code: "PG",
          country_name: "Papua New Guinea",
          dialing_code: "+675",
          index: 157,
        },
        {
          country_code: "PY",
          country_name: "Paraguay",
          dialing_code: "+595",
          index: 158,
        },
        {
          country_code: "PE",
          country_name: "Peru",
          dialing_code: "+51",
          index: 159,
        },
        {
          country_code: "PH",
          country_name: "Philippines",
          dialing_code: "+63",
          index: 160,
        },
        {
          country_code: "PL",
          country_name: "Poland",
          dialing_code: "+48",
          index: 161,
        },
        {
          country_code: "PT",
          country_name: "Portugal",
          dialing_code: "+351",
          index: 162,
        },
        {
          country_code: "PR",
          country_name: "Puerto Rico",
          dialing_code: "+1",
          index: 163,
        },
        {
          country_code: "QA",
          country_name: "Qatar",
          dialing_code: "+974",
          index: 164,
        },
        {
          country_code: "CG",
          country_name: "Republic of the Congo",
          dialing_code: "+242",
          index: 165,
        },
        {
          country_code: "RE",
          country_name: "Reunion",
          dialing_code: "+262",
          index: 166,
        },
        {
          country_code: "RO",
          country_name: "Romania",
          dialing_code: "+40",
          index: 167,
        },
        {
          country_code: "RU",
          country_name: "Russia",
          dialing_code: "+7",
          index: 168,
        },
        {
          country_code: "RW",
          country_name: "Rwanda",
          dialing_code: "+250",
          index: 169,
        },
        {
          country_code: "BL",
          country_name: "Saint Barthelemy",
          dialing_code: "+590",
          index: 170,
        },
        {
          country_code: "SH",
          country_name: "Saint Helena",
          dialing_code: "+290",
          index: 171,
        },
        {
          country_code: "KN",
          country_name: "Saint Kitts and Nevis",
          dialing_code: "+1",
          index: 172,
        },
        {
          country_code: "MF",
          country_name: "Saint Martin",
          dialing_code: "+590",
          index: 173,
        },
        {
          country_code: "PM",
          country_name: "Saint Pierre and Miquelon",
          dialing_code: "+508",
          index: 174,
        },
        {
          country_code: "VC",
          country_name: "Saint Vincent and the Grenadines",
          dialing_code: "+1",
          index: 175,
        },
        {
          country_code: "WS",
          country_name: "Samoa",
          dialing_code: "+685",
          index: 176,
        },
        {
          country_code: "SM",
          country_name: "San Marino",
          dialing_code: "+378",
          index: 177,
        },
        {
          country_code: "ST",
          country_name: "Sao Tome and Principe",
          dialing_code: "+239",
          index: 178,
        },
        {
          country_code: "SA",
          country_name: "Saudi Arabia",
          dialing_code: "+966",
          index: 179,
        },
        {
          country_code: "SN",
          country_name: "Senegal",
          dialing_code: "+221",
          index: 180,
        },
        {
          country_code: "RS",
          country_name: "Serbia",
          dialing_code: "+381",
          index: 181,
        },
        {
          country_code: "SC",
          country_name: "Seychelles",
          dialing_code: "+248",
          index: 182,
        },
        {
          country_code: "SL",
          country_name: "Sierra Leone",
          dialing_code: "+232",
          index: 183,
        },
        {
          country_code: "SG",
          country_name: "Singapore",
          dialing_code: "+65",
          index: 184,
        },
        {
          country_code: "SK",
          country_name: "Slovakia",
          dialing_code: "+421",
          index: 185,
        },
        {
          country_code: "SI",
          country_name: "Slovenia",
          dialing_code: "+386",
          index: 186,
        },
        {
          country_code: "SB",
          country_name: "Solomon Islands",
          dialing_code: "+677",
          index: 187,
        },
        {
          country_code: "SO",
          country_name: "Somalia",
          dialing_code: "+252",
          index: 188,
        },
        {
          country_code: "ZA",
          country_name: "South Africa",
          dialing_code: "+27",
          index: 189,
        },
        {
          country_code: "KR",
          country_name: "South Korea",
          dialing_code: "+82",
          index: 190,
        },
        {
          country_code: "ES",
          country_name: "Spain",
          dialing_code: "+34",
          index: 191,
        },
        {
          country_code: "LK",
          country_name: "Sri Lanka",
          dialing_code: "+94",
          index: 192,
        },
        {
          country_code: "LC",
          country_name: "St. Lucia",
          dialing_code: "+1",
          index: 193,
        },
        {
          country_code: "SD",
          country_name: "Sudan",
          dialing_code: "+249",
          index: 194,
        },
        {
          country_code: "SR",
          country_name: "Suriname",
          dialing_code: "+597",
          index: 195,
        },
        {
          country_code: "SZ",
          country_name: "Swaziland",
          dialing_code: "+268",
          index: 196,
        },
        {
          country_code: "SE",
          country_name: "Sweden",
          dialing_code: "+46",
          index: 197,
        },
        {
          country_code: "CH",
          country_name: "Switzerland",
          dialing_code: "+41",
          index: 198,
        },
        {
          country_code: "SY",
          country_name: "Syria",
          dialing_code: "+963",
          index: 199,
        },
        {
          country_code: "TW",
          country_name: "Taiwan",
          dialing_code: "+886",
          index: 200,
        },
        {
          country_code: "TJ",
          country_name: "Tajikistan",
          dialing_code: "+992",
          index: 201,
        },
        {
          country_code: "TZ",
          country_name: "Tanzania",
          dialing_code: "+255",
          index: 202,
        },
        {
          country_code: "TH",
          country_name: "Thailand",
          dialing_code: "+66",
          index: 203,
        },
        {
          country_code: "BS",
          country_name: "The Bahamas",
          dialing_code: "+1",
          index: 204,
        },
        {
          country_code: "GM",
          country_name: "The Gambia",
          dialing_code: "+220",
          index: 205,
        },
        {
          country_code: "TL",
          country_name: "Timor-Leste",
          dialing_code: "+670",
          index: 206,
        },
        {
          country_code: "TG",
          country_name: "Togo",
          dialing_code: "+228",
          index: 207,
        },
        {
          country_code: "TK",
          country_name: "Tokelau",
          dialing_code: "+690",
          index: 208,
        },
        {
          country_code: "TO",
          country_name: "Tonga",
          dialing_code: "+676",
          index: 209,
        },
        {
          country_code: "TT",
          country_name: "Trinidad and Tobago",
          dialing_code: "+1",
          index: 210,
        },
        {
          country_code: "TN",
          country_name: "Tunisia",
          dialing_code: "+216",
          index: 211,
        },
        {
          country_code: "TR",
          country_name: "Turkey",
          dialing_code: "+90",
          index: 212,
        },
        {
          country_code: "TM",
          country_name: "Turkmenistan",
          dialing_code: "+993",
          index: 213,
        },
        {
          country_code: "TC",
          country_name: "Turks and Caicos Islands",
          dialing_code: "+1",
          index: 214,
        },
        {
          country_code: "TV",
          country_name: "Tuvalu",
          dialing_code: "+688",
          index: 215,
        },
        {
          country_code: "UG",
          country_name: "Uganda",
          dialing_code: "+256",
          index: 216,
        },
        {
          country_code: "UA",
          country_name: "Ukraine",
          dialing_code: "+380",
          index: 217,
        },
        {
          country_code: "AE",
          country_name: "United Arab Emirates",
          dialing_code: "+971",
          index: 218,
        },
        {
          country_code: "GB",
          country_name: "United Kingdom",
          dialing_code: "+44",
          index: 219,
        },
        {
          country_code: "US",
          country_name: "United States",
          dialing_code: "+1",
          index: 220,
        },
        {
          country_code: "UY",
          country_name: "Uruguay",
          dialing_code: "+598",
          index: 221,
        },
        {
          country_code: "VI",
          country_name: "US Virgin Islands",
          dialing_code: "+1",
          index: 222,
        },
        {
          country_code: "UZ",
          country_name: "Uzbekistan",
          dialing_code: "+998",
          index: 223,
        },
        {
          country_code: "VU",
          country_name: "Vanuatu",
          dialing_code: "+678",
          index: 224,
        },
        {
          country_code: "VA",
          country_name: "Vatican City",
          dialing_code: "+39",
          index: 225,
        },
        {
          country_code: "VE",
          country_name: "Venezuela",
          dialing_code: "+58",
          index: 226,
        },
        {
          country_code: "VN",
          country_name: "Vietnam",
          dialing_code: "+84",
          index: 227,
        },
        {
          country_code: "WF",
          country_name: "Wallis and Futuna",
          dialing_code: "+681",
          index: 228,
        },
        {
          country_code: "YE",
          country_name: "Yemen",
          dialing_code: "+967",
          index: 229,
        },
        {
          country_code: "ZM",
          country_name: "Zambia",
          dialing_code: "+260",
          index: 230,
        },
        {
          country_code: "ZW",
          country_name: "Zimbabwe",
          dialing_code: "+263",
          index: 231,
        },
      ],
      showroles: false,
      userTypeItems: [],
      roles: [],
      roleType: { name: "", value: "" },
      roleTypes: [
        { name: "Admin", value: "ADMIN" },
        { name: "Manager", value: "MANAGER" },
        { name: "Executive", value: "EXECUTIVE" },
        // {name:'Staff',value:'STAFF'},
      ],
      name: "",
      nameArabic: "",
      language: "",
      selectedRole: "",
      selectedUserType: "",
      countryCode: "+971",
      country: "",
      email: "",
      password: "",
      repeatPassword: "",
      phoneNo: "",
      arabicField: false,

      processing: false,
      x: {
        loading: false,
        registerSuccess: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        required: (value) => !!value || "This field is required.",
        requiredLocation: (value) =>
          !!value.length || "This field is required.",
        emailRules: (v) =>
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
            v
          ) || "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        password: (v) =>
          /^.{6,}$/.test(v) || "Password should be minimum of 6 characters",
        phoneNumber: (v) =>
          /^(\d{5,16})$/.test(v) || "Please Enter Valid Phone No.",
        arabicLanguage: (v) =>
          /^([أ-ي]| [\u0600-\u06FF]|[@~`!@#$%^&*()_=+]|[الآخرين]|[ "]|['])+$/.test(
            v
          ) || "Please Enter Arabic language",
      },
    };
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  watch: {
    countrySortName() {
      if (this.countrySortName) {
        if (this.countrySortName.includes("KSA")) {
          this.arabicField = true;
        } else {
          this.arabicField = false;
        }
      }
    },
  },
  methods: {
    changeCurrency() {
      if (this.countrySortName) {
        this.multipleCountry.filter((x) => {
          if (x.value == this.countrySortName) {
            this.countryCurrency.push(x.currency);
          }
        });
      }
    },
    selectCountryCode() {
      this.countryCode = this.country.countryCode;
    },
    getAllUserType() {
      this.loading = true;
      let url = user.getWeightedUserType;
      delete this.axios.defaults.headers.common["token"];
      let body = {
        userId: localStorage.getItem("userId"),
      };
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.userTypeItems = response.data.data;
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Types";
        }
      );
    },
    getAllRoles() {
      this.loading = true;
      let url = getAllRoles;
      delete this.axios.defaults.headers.common["token"];
      this.axios.get(this.constants.rbacUrl + url).then(
        (response) => {
          this.roles = response.data.data;
        },
        (error) => {
          this.x.error = "Failed to Fetch Roles.";
        }
      );
    },
    getLanguage() {
      this.loading = true;
      let url = getLanguage;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.get(this.constants.adminAPI + url, config).then(
        (response) => {
          this.languageArray = response.data.data;
        },
        (error) => {
          this.x.error = "Failed to Fetch Language.";
        }
      );
    },
    close() {
      this.$router.go(-1);
    },
    cleanJson(customerProfile) {
      let newCustomerProfile = customerProfile;
      delete newCustomerProfile["arabicField"];
      delete newCustomerProfile["languageArray"];
      delete newCustomerProfile["valid"];
      delete newCustomerProfile["lazy"];

      delete newCustomerProfile["language"];
      delete newCustomerProfile["multipleCountry"];
      delete newCustomerProfile["password"];
      delete newCustomerProfile["repeatPassword"];
      delete newCustomerProfile["processing"];
      delete newCustomerProfile["rules"];
      delete newCustomerProfile["roles"];
      delete newCustomerProfile["valid"];
      delete newCustomerProfile["selectedRole"];
      delete newCustomerProfile["showroles"];
      delete newCustomerProfile["selectedUserType"];
      delete newCustomerProfile["userTypeItems"];
      delete newCustomerProfile["countries"];
      delete newCustomerProfile["country"];
      if (!this.countrySortName.includes("KSA")) {
        delete newCustomerProfile["nameArabic"];
      }
      delete newCustomerProfile["roleTypes"];
      newCustomerProfile.email = customerProfile.email.toLowerCase();
      return newCustomerProfile;
    },
    checkRole() {
      this.selectedUserType;
      if (this.selectedUserType.userTypeName == "others") {
        this.showroles = true;
        this.getAllRoles();
      } else {
        this.showroles = false;
      }
    },
    assignPermissionToUser(userObj) {
      let body = userObj;
      this.loading = true;
      let url = user.assignPermissiontoUser;
      delete this.axios.defaults.headers.common["token"];

      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.loading = false;
          this.x.registerSuccess = true;
        },
        (error) => {
          this.x.error = "Failed to Assign Permissions to User";
          this.loading = false;
        }
      );
    },
    register() {
      if (this.$refs.form.validate()) {
        if (this.processing) {
          return;
        } else {
          this.x.error = null;
          const url = `${this.constants.adminAPI}/admin/signUp`;
          const { x, ...customerProfile } = this._data;
          const mandatory = [
            "name",
            "email",
            "countryCode",
            "phoneNo",
            "countrySortName",
            "language",
            "country",
            "selectedUserType",
          ];
          const missingValueForKey = mandatory.find(
            (key) =>
              typeof customerProfile[key] !== "boolean" && !customerProfile[key]
          );
          if (missingValueForKey) {
            const field =
              missingValueForKey.slice(0, 1).toUpperCase() +
              missingValueForKey.slice(1).replace(/([A-Z])/g, " $1");
            this.x.error = `Missing value for the field: ${field}`;

            return;
          } else {
            this.processing = true;
            delete this.axios.defaults.headers.common["token"];
            let token = localStorage.getItem(StorageKeys.SessionKey);
            let config = {
              headers: {
                authorization: `bearer ${token}`,
              },
            };
            let newCustomerProfile = this.cleanJson(customerProfile);
            this.x.loading = true;

            newCustomerProfile.languageId = this.language._id;

            if (this.countrySortName) {
              this.multipleCountry.filter((x) => {
                for (let i = 0; i < this.countrySortName.length; i++) {
                  if (x.value == this.countrySortName[i]) {
                    this.countryCurrency.push(x.currency);
                  }
                }
              });
            }
            newCustomerProfile.country = this.country.country_name;
            newCustomerProfile.countryCode =
              this.countryCode.dialing_code || this.countryCode;
            if (!this.valid) {
              this.x.error = `There is error in validation. Please validate all fields.`;
              this.processing = false;
              this.x.loading = false;
              return;
            }

            if (!this.roleType) {
              this.x.error = "Please provide a designation";
              this.processing = false;
              this.x.loading = false;
              return;
            } else {
              newCustomerProfile.roleType = this.roleType.value;
            }
            this.axios.post(url, { ...newCustomerProfile }, config).then(
              (response) => {
                if (response.status === 200) {
                  let obj = {
                    userId: response.data.data.userId,
                    permissions: [],
                    roles: [],
                    userType: "",
                  };
                  if (this.showRoles) {
                    obj.roles = [this.selectedRole._id];
                  }
                  obj.userType = this.selectedUserType._id;
                  this.assignPermissionToUser(obj);
                  this.processing = false;
                } else {
                  this.processing = false;
                  this.x.error = "Failed to Register User.";
                }
                this.x.loading = false;
                this.processing = false;
              },
              (error) => {
                this.processing = false;
                this.x.loading = false;
                this.x.error = this.$eventBus.parse(error);
              }
            );
          }
        }
      } else {
        this.x.error = "Please fill all required fields in above form !";
      }
    },
    closeRegistration() {
      this.registerSuccess = false;
      this.$router.push("/user-management");
    },
  },
};
</script>

<style scoped lang="scss">
.menu,
.menu__content {
  background: white !important;
}
.menuable__content__active {
  min-width: 290px !important;
}
</style>
